<div class="inner_content_block">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12">
        <div class="title_block">
          <h1 class="float-start">Overview</h1>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
        <div class="dashboard_block">

          <div class="row">
            <div class="col-12 col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <div class="number_block_dashboard">
                <a routerLink="/manage/organization/list">
                  <span>{{organizationCount}}</span>Organizations
                  <div class="clearfix"></div>
                </a>
              </div>
            </div>
            <div class="col-12 col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <div class="number_block_dashboard">
                <a routerLink="/manage/division/list">
                  <span>{{divisionCount}}</span>Divisions
                  <div class="clearfix"></div>
                </a>
              </div>
            </div>
            <div class="col-12 col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <div class="number_block_dashboard">
                <a (click)="backToOrg(true)" routerLink="/manage/unique-users">
                  <span>{{userCount}}</span>Unique Users
                  <div class="clearfix"></div>
                </a>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="row">
            <!-- <div class="col-12 col-xl-12 col-lg-12 col-md-12 d-flex border_line">
              <div class="dashboard_box pb-0">
                <div class="box_title">
                  <h5 class="mb-0 float-start mt-2">Organizations by System</h5>
                  <a class="float-end view_all no_border mt-3"
                  routerLink="/system-wise-details"><i class="fa fa-eye"></i> View
                  All</a>
                  <div class="k-form" class="float-end sort_block">
                    <label>Sorted by: </label>
                    <div class="mb-0 d-inline-block">
                        <kendo-dropdownlist [data]="listItems" [(ngModel)]="system_sort"
                            textField="text" valueField="value" [valuePrimitive]="true"
                            (valueChange)="changeSortBySystem()">
                            <ng-template kendoMultiSelectNoDataTemplate>
                                <h6>NO DOMAIN FOUND</h6>
                            </ng-template>
                        </kendo-dropdownlist>
                    </div>
                </div>
                 
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.organizationsBySystems">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box_data_not_found" *ngIf="dataNotAvailable.organizationsBySystems">
                  <div class="height_block">
                    Data Not Available
                    </div>
                </div>
                <p class="pb-3">The chart below shows the distribution of Organizations by System based on the total number
                  of Organizations, Divisions and Units in each System.</p>
                <div class="clearfix"></div>
                <div class="responsive_chart">
                  <div id="org_user_system_bar_chart" class="user_chart_height margin_left_chart"></div>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-12 d-flex border_line">
                <div class="dashboard_box pb-0">
                  <div class="box_title">
                    <h5 class="mb-0 float-start">Users by System</h5>
                    <div class="clearfix"></div>
                  </div>
                  <p class="pb-3">This is the placeholder for Users by System.</p>
                  <div class="clearfix"></div>
                  <div id="user_system_chart" class="user_chart_height"></div>
                </div>
              </div> -->
            <!-- <div class="col-12 col-xl-6 col-lg-12 col-md-12 d-flex border_line">
              <div class="dashboard_box pb-0">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Organizations by Ownership</h5>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.organizationsByOwnership">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box_data_not_found" *ngIf="dataNotAvailable.organizationsByOwnership">
                  <div class="height_block">
                    Data Not Available
                    </div>
                </div>
                <p class="pb-3">The chart below shows the distribution of Organizations by Ownership based on the total
                  number of Organizations in each.</p>
                <div class="clearfix"></div>
                <div class="responsive_chart">
                <div id="org_ownership_chart" class="user_chart_height"></div>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-12 col-xl-6 col-lg-12 col-md-12 d-flex border_line">
              <div class="dashboard_box pb-0">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Organizations by Type</h5>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.organizationsByType">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box_data_not_found" *ngIf="dataNotAvailable.organizationsByType">
                  <div class="height_block">
                    Data Not Available
                    </div>
                </div>
                <p class="pb-3">The chart below shows the distribution of Organizations by Type based on the total number of
                  Organizations in each.</p>
                <div class="clearfix"></div>
              
                <div id="org_type_chart" class="user_chart_height"></div></div>
            
            </div> -->
            <div class="col-12 col-xl-12 col-lg-12 col-md-12 d-flex border_line">
              <div class="dashboard_box pb-0">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Recently Connected Organizations</h5>
                  <a *ngIf="recentlyConnectedOrganizations?.length > 0" class="float-end view_all no_border"
                    routerLink="/manage/organization/list"><i class="fa fa-eye"></i> View
                    All</a>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.recentlyConnectedOrganizations">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="pb-3">The list below shows recently connected organizations. Click <b>View All</b>to see the
                  entire list of organizations.</p>
                <div class="clearfix"></div>
                <div class="table-responsive pb-3">
                  <table class="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="width: 20%;">Organization</th>
                        <th style="width: 10%;">City</th>
                        <th style="width: 10%;">County</th>
                        <th style="width: 5%;">State</th>
                        <th style="width: 5%;">ZipCode</th>
                        <th style="width: 10%;">Connected by</th>
                        <th style="width: 8%;">Connected on</th>
                        <!-- <th style="width: 10%;">Last 6 Digits of FEIN</th> -->
                        <!-- <th style="width: 15%;">System</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let organization of recentlyConnectedOrganizations; let i=index;">
                        <td>{{organization.institute_name}}</td>
                        <td>{{organization.city}}</td>
                        <td>{{organization.counties}}</td>
                        <td>{{organization.state_abbreviation}}</td>
                        <td>{{organization.zipcode}}</td>
                        <td>{{organization.Connected_by == " " ? "NA" :organization.Connected_by}}</td>
                        <td>{{organization.Connected_on | date:"MMMM d, yyyy hh:mm a" : 'UTC'}}</td>
                        <!-- <td>{{organization.fein_number}}</td> -->
                        <!-- <td>{{organization.system_name}}</td> -->
                      </tr>
                      <tr *ngIf="recentlyConnectedOrganizations.length == 0">
                        <td colspan="8" style="text-align: center;">Data Not Available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 col-xl-12 col-lg-12 col-md-12 d-flex border_line">
              <div class="dashboard_box">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Top Organizations</h5>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.topOrganizations">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box_data_not_found" *ngIf="dataNotAvailable.topOrganizations">
                  <div class="height_block">
                    Data Not Available
                    </div>
                </div>
                <p class="pb-5">This chart shows organizations with and without system affiliations, ranked highest to lowest by the total number of users.</p>
                <div class="clearfix"></div>
                <div class="responsive_chart">
                <div class="org_title_chart">
                <div class="row justify-content-center">
                  <div class="col-6 col-xl-6 col-lg-6 col-md-6">
                    <h5 class="text-center">Organizations With System</h5>
                  </div>
                  <div class="col-6 col-xl-6 col-lg-6 col-md-6">
                    <h5 class="text-center">Organizations Without System</h5>
                  </div>
                </div></div>
                <div class="clearfix"></div>
                
                <div id="with_without_org_chart" class="user_chart_height"></div>
                </div>
              </div>
            </div> -->
            <div class="col-12 col-xl-6 col-lg-12 col-md-12 d-flex border_line">
              <label class="chart_lbl">Scroll right to view the entire chart</label>
              <div class="dashboard_box mb-0">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Organizations by State</h5>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.stateWiseOrganizationsAndUsers">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box_data_not_found" *ngIf="dataNotAvailable.stateWiseOrganizationsAndUsers">
                  <div class="height_block">
                    Data Not Available
                  </div>
                </div>
                <p class="pb-2 float-start">The map below shows the distribution of active organizations in each
                  state.
                </p>
                <!-- <form class="k-form float-end mt-3 mb-5 org_switch" [formGroup]="stateForm">
                  <fieldset class="k-form-fieldset">
                    <kendo-formfield class="label_switch">
                      <span class="text">By Organizations</span>
                      <kendo-switch formControlName="switch_state" #switch_state [onLabel]="''" [offLabel]="''">
                      </kendo-switch>
                      <span class="text">By Users</span>
                    </kendo-formfield>
                  </fieldset>
                </form> -->
                <div class="clearfix"></div>
                <div class="responsive_chart">
                  <div id="map_chart_org" class="state_chart_height"></div>
                  <!-- <div id="map_chart_org" [hidden]="stateForm.controls.switch_state.value" class="state_chart_height"></div> -->
                  <!-- <div id="map_chart_user" [hidden]="!stateForm.controls.switch_state.value" class="state_chart_height"></div> -->
                  <!-- <div class="text-center"><img src="{{globals.cndURL}}assets/images/map-admin.png" class="img-fluid" /></div> -->
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="col-12 col-xl-6 col-lg-12 col-md-12 d-flex border_line res_mt20">
              <label class="chart_lbl">Scroll right to view the entire chart</label>
              <div class="dashboard_box mb-0">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Users by State</h5>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.stateWiseOrganizationsAndUsers">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box_data_not_found" *ngIf="dataNotAvailable.stateWiseOrganizationsAndUsers">
                  <div class="height_block">
                    Data Not Available
                  </div>
                </div>
                <p class="pb-2 float-start">The map below shows the distribution of active users in each state.
                </p>
                <!-- <form class="k-form float-end mt-3 mb-5 org_switch" [formGroup]="stateForm">
                  <fieldset class="k-form-fieldset">
                    <kendo-formfield class="label_switch">
                      <span class="text">By Organizations</span>
                      <kendo-switch formControlName="switch_state" #switch_state [onLabel]="''" [offLabel]="''">
                      </kendo-switch>
                      <span class="text">By Users</span>
                    </kendo-formfield>
                  </fieldset>
                </form> -->
                <div class="clearfix"></div>
                <div class="responsive_chart">
                  <div id="map_chart_user" class="state_chart_height"></div>
                  <!-- <div class="text-center"><img src="{{globals.cndURL}}assets/images/map-admin.png" class="img-fluid" /></div> -->
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>