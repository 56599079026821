<div class="inner_content_block wo_org">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">

                    <h1 class="float-start">Coupons</h1>
                    <button kendoButton routerLink="/manage/coupon/add" class="all_btn theme_btn float-end"
                        *ngIf="globals.permissions['add-coupon']">Add a Coupon</button>

                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <div *ngIf="couponList.total>0" class="filter_block mb-3">
                    <ul>
                        <li class="grey"><i class="fa fa-pencil"></i>Edit</li>
                        <li class="red"><i class="fa fa-trash"></i>Delete</li>
                    </ul>
                </div>


                <div class="clearfix"></div>
                <div class="loader_table">
                    <div class="box_loader" *ngIf="gridLoading">
                        <div class="height_loader">
                            <div class="img_loader">
                                <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                                <div class="loader">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <kendo-grid [data]="couponList" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
                        (pageChange)="pageChange($event)" [sort]="sort" [sortable]="{
                          allowUnsort: false,
                          mode: 'single'
                          }" (sortChange)="sortChange($event)" scrollable="none">
                        <kendo-grid-messages *ngIf="couponList.total==0" pagerItems="Coupons"
                            pagerItemsPerPage="Coupons per page" noRecords="No Coupons available.">
                        </kendo-grid-messages>
                        <ng-template kendoGridToolbarTemplate>
                            <input placeholder="Search by coupons, discount type and value" kendoTextBox
                                (input)="onFilter($event.target.value)" />
                            <kendo-pager-page-sizes [pageSizes]="globals.pagesizes"></kendo-pager-page-sizes>
                        </ng-template>
                        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                            <kendo-pager-prev-buttons *ngIf="couponList.total>0"></kendo-pager-prev-buttons>
                            <kendo-pager-numeric-buttons
                                [buttonCount]="globals.pageIndex" *ngIf="couponList.total>0"></kendo-pager-numeric-buttons>
                            <kendo-pager-next-buttons *ngIf="couponList.total>0"></kendo-pager-next-buttons>
                            <kendo-pager-info *ngIf="couponList.total>0"></kendo-pager-info>
                        </ng-template>
                        <kendo-grid-column field="name" title="Coupon Name" [headerStyle]="{width: '20%'}"
                            [style]="{width: '20%'}" >
                        </kendo-grid-column>
                        <kendo-grid-column field="display_text" title="Discount Type" [headerStyle]="{width: '10%'}"
                            [style]="{width: '10%'}" >
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{dataItem.display_text == 'Percentage' ? 'Percentage': 'Amount'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="value" title="Value" [headerStyle]="{width: '10%','text-align': 'center'}" [style]="{width: '10%'}"
                                         class="text-center">
                            <ng-template kendoGridCellTemplate let-dataItem>
                           
                            {{dataItem.coupon_type_id==261 ? "$ " + dataItem.value  : dataItem.value + "%"}}
                        </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="expiry_date" title="Expiry Date" [headerStyle]="{width: '15%'}"
                            [style]="{width: '15%'}" >
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.expiry_date | date:"MMMM d, yyyy"}}
                                 
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="is_active" title="Is Active?"
                            [headerStyle]="{width: '7%','text-align': 'center'}" [style]="{width: '7%'}"
                             [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-coupon']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <!-- <kendo-switch (valueChange)="updateStatus(dataItem,rowIndex)" [(ngModel)]="dataItem.is_active" [checked]="dataItem.is_active==1" [onLabel]="'Yes'" [offLabel]="'No'"></kendo-switch> -->
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Actions" [headerStyle]="{width: '7%','text-align': 'center'}"
                            [style]="{width: '7%'}"  [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-coupon'] || globals.permissions['delete-coupon']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    <button kendoButton (click)="edit(dataItem.id)" title="Edit" class="icon_btn grey"
                                        *ngIf="globals.permissions['edit-coupon']"><i class="fa fa-pencil"></i></button>
                                    <button kendoButton (click)="delete(dataItem)" [disabled]="dataItem.used_count>0"
                                        title="Delete" class="icon_btn red"
                                        *ngIf="globals.permissions['delete-coupon']"><i
                                            class="fa fa-trash"></i></button>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <!-- <kendo-grid-column field="name" title="Coupon Name" width="200" media="(max-width: 768px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="display_text" title="Discount Type" width="200"
                            media="(max-width: 768px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{dataItem.display_text == 'Percentage' ? '%': 'USD'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="value" title="Value" width="200" media="(max-width: 768px)">
                            
                        </kendo-grid-column>
                        <kendo-grid-column field="expiry_date" title="Expiry Date" width="100"
                            media="(max-width: 768px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.expiry_date | date:'MMM d, y' }}
                            </ng-template>

                        </kendo-grid-column>
                        <kendo-grid-column field="is_active" title="Is Active?" width="95" media="(max-width: 768px)"
                            [headerStyle]="{'text-align': 'center'}" [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-coupon']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">                                
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Actions" width="85" media="(max-width: 768px)"
                            [headerStyle]="{'text-align': 'center'}" [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-coupon'] || globals.permissions['delete-coupon']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    <button kendoButton (click)="edit(dataItem.id)" title="Edit" class="icon_btn grey"
                                        *ngIf="globals.permissions['edit-coupon']"><i class="fa fa-pencil"></i></button>
                                    <button kendoButton (click)="delete(dataItem)" [disabled]="dataItem.used_count>0"
                                        title="Delete" class="icon_btn red"
                                        *ngIf="globals.permissions['delete-coupon']"><i
                                            class="fa fa-trash"></i></button>
                                </div>
                            </ng-template>
                        </kendo-grid-column> -->
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>